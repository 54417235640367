import { ref, computed, onMounted } from 'vue'
import { differenceInSeconds } from 'date-fns'

export const useDismissable = (props) => {
  const isDismissed = ref(false)

  onMounted(() => {
    if (canDismiss.value) {
      const dismissedTime = Number.parseInt(
        window.localStorage.getItem(itemKey.value) || ''
      )
      if (!Number.isNaN(dismissedTime)) {
        if (props.dismissalDuration === 0) {
          // Zero means FOREVER
          isDismissed.value = true
        } else {
          const dismissedAt = new Date(dismissedTime)
          isDismissed.value = differenceInSeconds(new Date(), dismissedAt) < props.dismissalDuration
        }
        // If we've exceeded the duration, we might as well clear out the storage item
        if (isDismissed.value) {
          undismiss()
        }
      }
    }
  })

  /**
     * Marks the component as having been dismissed
     */
  const dismiss = () => {
    if (canDismiss.value) {
      window.localStorage.setItem(itemKey.value, (new Date()).getTime().toString())
      isDismissed.value = true
    }
  }

  /**
     * Unmarks the component as having been dismissed
     */
  const undismiss = () => {
    if (canDismiss.value) {
      window.localStorage.removeItem(itemKey.value)
      isDismissed.value = false
    }
  }

  const canDismiss = computed(() => {
    return (props.dismissalDuration >= 0) && !!props.dismissalIdentifier
  })

  const itemKey = computed((): string => {
    return `dismiss-${props.dismissalIdentifier}`
  })

  return {
    isDismissed,
    dismiss,
    undismiss
  }
}

/**
 * @Prop dismissalDuration The number of seconds that the component can be dismissed (zero means forever)
 * @Prop dismissalIdentifier A unique identifier that represents this component
 */
export const withDismissableProps = {
  dismissalDuration: {
    type: Number,
    required: false,
    default: 0
  },
  dismissalIdentifier: {
    type: String,
    required: false
  }
}
